const GLOBAL_CONSTANT = {
    "CHAT_API": "https://chat.beta.cybersmarties.com.tr",
    "API_URL": "https://api.beta.cybersmarties.com.tr",
    "TIME_ZONE": "Europe/Dublin",
    "OFFLINE": "true",
    "TEXT_SPEECH_API_URL": "https://aidennvz09.execute-api.eu-west-1.amazonaws.com",
}


export default GLOBAL_CONSTANT;
