import React, { useState } from "react";
import env from "react-dotenv";
import { Link, useNavigate } from "react-router-dom";
import { user_login, online_offline } from '../../../services/user_apis';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import TextToSpeech from "../../../services/textToSpeech";

const Login = (props) => {
    const { t } = useTranslation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [invalid, setinvalid] = useState(false)
    const [visible, setVisibility] = useState(true);
    const [status, setStatus] = useState("");

    const navigate = useNavigate()
    props.chatHandle(false);

    React.useEffect(() => {
        localStorage.setItem("language", "tu")
        onlineOrOffline();
    }, []);

    const onlineOrOffline = async () => {
        let resp = await online_offline();
        if (resp.code === '-1') setStatus('online');
        if (resp.code === '-2') setStatus('maintainence');
        if (resp.code === 0) setStatus('offline');
    }
    const showMessage = (msg) => {
        Swal.fire({
            title: t('Cybersmarties'),
            text: t(msg),
            confirmButtonText: t('Okay')
        })
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        let login_body = {
            username,
            user_password: password,
            device_type: "W",
            os_version: "os",
            device_name: "device",
            ip: "3.4.5",
            device_token: "device_token",
            token: "abc"
        }
        let login_body1 = JSON.stringify(login_body)
        let response = await user_login(login_body1)
        if (response.code === 1) {
            // console.log(response.data);
            localStorage.setItem('user', JSON.stringify(response.data));
            localStorage.setItem("userId", response.data.user_id);
            localStorage.setItem("isLogin", true);
            localStorage.setItem("nCount", 0);
            setinvalid(false)
            // navigate("/home")
            loginRedirectCall()
            props.chatHandle(true);

        } else if (response.code === -1) {
            showMessage(response.message)
        } else {
            setinvalid(true)
        }

        return
    };


    function loginRedirectCall() {
        let path = window.location.protocol + "//" + window.location.host + "/home"
        window.location.href = path;
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    const offlineComponent = () => {
        return (
        <div className="col-lg-12" data-message={t("Shutdown message")}>
            <br />
            <div className="form-group text-center padding10">
                <p><TextToSpeech text={t("Hi Cybersmartie!!, Cybersmarties platform shuts down every night from 9pm to 7am. It is important to spend time with your family and get at least 8 hours of good sleep every night to stay positive and healthy. You have a big day tomorrow to look forward to, so get a good night's sleep. See you in the morning!")} /></p>
            </div>
        </div>)
    }

    const maintenaceComponent = () => {
        return (
        <div className="col-lg-12" data-message={t("Maintenance message")}>
            <br />
            <div className="form-group text-center padding10">
                <p><TextToSpeech text={t("Hi Friends, we are making some changes to the platform to serve you better. It will be up again shortly. Can't wait to see you back again! :)")} /></p>
            </div>
        </div>)
    }

    const emergencyOffline = () => {
        return (
            <div className="col-lg-12" data-message={t("Emergency offline message")}>
            <br />
            <div className="form-group text-center padding10">
                <p><TextToSpeech text={t("Sorry kids, we need to give Cybersmarties an update. We will be back up and running very soon")} /></p>
            </div>
        </div>)
    }

    const loginComponent = () => {
        return (
            <>
                <div className="col-lg-6" data-message={t("Login box")}>
                    <div className="auth-form" role="tabpanel">
                        <h2><TextToSpeech text={t("Hesabınıza Giriş Yapın")} /></h2>
                        <div className="form-group">
                            <label for={t("Kullanıcı Adını Girin")} className="hidden">{t("Kullanıcı Adını Girin")}</label>
                            <input
                                type="text"
                                name=""
                                className="form-control"
                                placeholder={t("Kullanıcı Adını Girin")}
                                value={username}
                                onChange={(e) => {
                                    setUsername(
                                        e.target.value
                                    );
                                }}
                                required
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                        <div className="form-group password-input">
                            <label for={t("Şifreyi Girin")} className="hidden">{t("Şifreyi Girin")}</label>
                            <input
                                type={visible ? "password" : "text"}
                                name=""
                                className="form-control"
                                placeholder={t("Şifreyi Girin")}
                                value={password}
                                onChange={(e) => {
                                    setPassword(
                                        e.target.value
                                    );
                                }}
                                onKeyPress={handleKeyPress}
                                required
                            />
                            <i className={`password-eye-icon ${visible ? 'far fa-eye' : 'far fa-eye-slash'}`} onClick={() => setVisibility(!visible)} id="togglePassword"></i>
                        </div>

                        {invalid && <h4 className="text-danger"><TextToSpeech text={t("Invalid Credentials")} /></h4>}
                        <div className="form-group text-center">
                            <button
                                data-message={t("Login to your Cybersmarties Account")}
                                className="cybersmart-btn btn-block"
                                onClick={handleSubmit}
                            >
                               <TextToSpeech text={t("Hesabınıza Giriş Yapın")} />
                            </button >
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" role="tabpanel">
                    <div className="auth-details">
                        <p>
                            <TextToSpeech text={t("Arkadaşlarla sohbet etmek yeni arkadaşlar edinmek oyun oynamak müzik dinlemek kod yazmayı öğrenmek çizgi film izlemek ve çok daha fazlasını yapmak ister misiniz?")} />
                        </p>
                        <p>
                            <TextToSpeech text={`${t("Cybersmarties")} <span className="text-purple">${t("SADECE ÇOCUKLAR İÇİN")}</span>. ${t("Tablet PC dizüstü bilgisayar Chromebook Mac veya akıllı telefonlarınızda kullanabilirsiniz. Eğer")} <span className="text-purple">${t("ŞİFRENİZİ UNUTTUYSANIZ")}</span>   ${t("lütfen öğretmeninizden isteyin.")}`} />
                        </p>
                    </div>
                </div>
            </>
        );
    }

    return (
        <React.Fragment>
            <div className="content">
                <div className="authentication-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-md-12">
                                <div className="signin-box" data-message={t("Sign in to Cybersmarties")}>
                                    <div className="auth-logo">
                                        <Link to={"/"} title={t("Cybersmarties")}>
                                            <img alt={t("Cybersmarties Logo")}
                                                src={`assets/images/CSLogo.png`}
                                                className="img-fluid"
                                            />
                                        </Link>
                                    </div>
                                    <div className="row">
                                        {status === 'offline' ? offlineComponent() : status === 'maintainence' ? maintenaceComponent() : status === 'online' ? loginComponent() : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
