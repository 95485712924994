import React, { useEffect } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import myclassbg from "./my-class-img/myclassbg.png"
import { Link, useNavigate } from 'react-router-dom';
import { home_work_list_body, user_list_api, lecture_list_api, teacher_class_division_list, delete_lecture, submission_list_teacher, result_list_teacher, school_detail } from '../../../services/user_apis'
import { division_details_api } from '../../../services/user_apis';
import moment from 'moment'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import TextToSpeech from '../../../services/textToSpeech';
import { time12Hour, timeDurationDetails } from '../../../Utils/TimeUtils';

const My_Homework_Teacher = ({ socketData }) => {

    const { t } = useTranslation();
    let user = JSON.parse(localStorage.getItem('user'));
    let [class_list, setClassList] = React.useState([])
    let [post, setpost] = React.useState([])
    let [homeworklist, sethomeworklist] = React.useState([])
    let [homeworkSubmitlist, sethomeworkSubmitlist] = React.useState([])
    let [homeworkResultlist, sethomeworResultlist] = React.useState([])
    let [annoucement, setannoucement] = React.useState([])
    let [lecture, setlecturelist] = React.useState([])
    let navigate = useNavigate();
    let [classDetails, setClassDetails] = React.useState({});
    let [selectClassData, setSelectClassData] = React.useState(null);
    let [tab, setTab] = React.useState("homework");
    let [divisionDetails, setDivisionDetails] = React.useState(null)
    let [instructionData, setInstructionData] = React.useState("");
    let [schoolData, setschooldata] = React.useState([]);
    let [onlineClassdata, setonlineClassData] = React.useState(null);

    useEffect(() => {
        const class_listApi = async () => {
            if (user.user_role === "teacher" || user.user_role === "teacher") {
                const response = await teacher_class_division_list()
                if (response.code === 1) {
                    setClassList(response.data)
                    if (response.data.length >= 1) {
                        setClassData(response.data[0])
                        setSelectClassData(response.data[0])
                        handlelecturelist(response.data[0].class_id, response.data[0].division_id)
                        homework_list_api(response.data[0].class_id, response.data[0].division_id)
                        submission_list_teacher_api(response.data[0].class_id, response.data[0].division_id)
                        result_list_teacher_api(response.data[0].class_id, response.data[0].division_id)
                        getDivisionDetails(response.data[0].division_id)
                    }
                }
            }
        }
        class_listApi()
        school_details()
    }, [])

    const school_details = async () => {

        const school_data = await school_detail(user.school_id)
        if (school_data.code === 1) {
            setschooldata(school_data.data);
        } else {
            setschooldata("")
        }

    }
    const homework_list_api = async (classId, devisionId) => {
        // console.log("Home Work APi call")
        var body = { "class_id": classId, "user_id": user.user_id, "division_id": devisionId }
        const response = await home_work_list_body(body)
        if (response.code === 1) {
            // console.log("Home Work Listing", response)
            sethomeworklist(response.data)
        }
        if (response.code === 2) {
            // console.log("No homework found");
            sethomeworklist([]);
        }
    }

    const submission_list_teacher_api = async (classId, devisionId) => {
        var body = { "class_id": classId, "teacher_id": user.user_id, "division_id": devisionId }
        const response = await submission_list_teacher(body)
        if (response.code === 1) {
            // console.log("Submission Listing", response)
            sethomeworkSubmitlist(response.data)
        }
        if (response.code === 2) {
            // console.log("No Submission Listing")
            sethomeworkSubmitlist([])
        }
    }

    const result_list_teacher_api = async (classId, devisionId) => {
        var body = { "class_id": classId, "teacher_id": user.user_id, "division_id": devisionId }
        const response = await result_list_teacher(body)
        if (response.code === 1) {
            // console.log("Result Listing", response)
            sethomeworResultlist(response.data)
        }
        if (response.code === 2) {
            // console.log("No Result Listing")
            sethomeworResultlist([])
        }
    }

    const handlelecturelist = (classId, devisionId) => {
        let current_time = moment().utc().format();
        const post_body = {
            current_datetime: current_time,
            class_id: classId,
            division_id: devisionId,
        }
        const lecuture_list = async () => {
            const response1 = await lecture_list_api(post_body)
            if (response1.code === 1) {
                setlecturelist(response1.data)
            } else {
                setlecturelist([])
            }
        }
        lecuture_list()
    }

    const changeClassSelection = (event) => {
        var selectData;
        class_list.map((item) => {
            if (item.user_class_division_id == event.target.value) {
                selectData = item
            }
        });
        // console.log(selectData);
        setClassData(selectData)
        setSelectClassData(selectData)

        setlecturelist([])
        handlelecturelist(selectData.class_id, selectData.division_id)

        sethomeworklist([])
        homework_list_api(selectData.class_id, selectData.division_id)

        sethomeworkSubmitlist([])
        submission_list_teacher_api(selectData.class_id, selectData.division_id)

        sethomeworResultlist([])
        result_list_teacher_api(selectData.class_id, selectData.division_id)

        setDivisionDetails(null)
        getDivisionDetails(selectData.division_id)

    }


    const getDivisionDetails = async (division_id) => {
        // console.log("call division list data ")
        let post_body = {
            division_id: division_id
        }
        try {
            const response = await division_details_api(post_body)
            // console.log("Divisions Details api  ===> ", response)
            if (response.code === 1) {
                setDivisionDetails(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }

    }


    const setClassData = (data) => {
        setClassDetails({ "class_name": data.class_name, "school_name": user.school_name, "division_name": data.division_name, "class_id": data.class_id, "division_id": data.division_id })
    }

    const clickHomeWorkCreate = () => {
        navigate("/add-home-work/c&" + classDetails.class_id + "&" + classDetails.division_id)
    }
    const clickOnlineClassCreate = () => {
        navigate("/add-online-class/c&" + classDetails.class_id + "&" + classDetails.division_id)

    }
    const handleMore = (lecture_id) => {
        $(`.menu-` + lecture_id).toggleClass('more-active');
    };

    const handleDeleteClass = async (lecture_id) => {
        let post_body = {
            lecture_id: lecture_id
        }

        const response1 = await delete_lecture(post_body)
        if (response1.code === 1) {
            $(`.menu-` + lecture_id).removeClass('more-active');
            handlelecturelist(classDetails.class_id, classDetails.division_id)
        }
    }

    const handleEditClass = (classData) => {
        navigate("/add-online-class/c&" + classDetails.class_id + "&" + classDetails.division_id, { state: { classData, isEdit: true } })
    }

    return (
        <React.Fragment>

            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid text-left">
                        <div className="row">
                            <div className="col-lg-8 pr-md-0">
                                <div className="main-content">
                                    <div className='my-profile school-profile myclass-profile'>
                                        <div className="p-banner" style={{ backgroundimage: `url(${(divisionDetails && divisionDetails.division_cover_image_full_path) ? divisionDetails.division_cover_image_full_path : myclassbg})` }}>
                                            <img src={(divisionDetails && divisionDetails.division_cover_image_full_path) ? divisionDetails.division_cover_image_full_path : myclassbg} />

                                        </div>
                                        <div className="p-icon">
                                            <div className="p-main-pic">
                                                <img src={(divisionDetails && divisionDetails.division_logo_image_full_path) ? divisionDetails.division_logo_image_full_path : `${env.FRIST_CLASS_SVG}first-class.svg`} />
                                            </div>
                                        </div>
                                        <div className="p-name pb-3 d-flex justify-content-center alignItems-center">
                                            {
                                                user.user_role === "teacher" && <div className="my-class-dropdown">
                                                    <label for={t("Select Class")} className="hidden">{t("Select Class")}</label>
                                                    <select aria-label={t("Select Class")} className="form-control" onChange={changeClassSelection}>
                                                        {class_list.map((value, i) => (
                                                            <option key={i} value={value.user_class_division_id}><TextToSpeech text={`${t(value.class_name)} - ${t(value.division_name)}`} /></option>
                                                        ))}

                                                    </select>
                                                </div>

                                            }
                                        </div>
                                        <br></br>
                                        <div className="post-content">
                                            <ul role="tablist" className="nav nav-tabs"  >
                                                <li role="presentation"><a href="#homework" data-toggle="tab" className="border-0 active" onClick={() => setTab("homework")} title={t('Homework')}> <TextToSpeech text={t('Homework')} /></a></li>
                                                <li role="presentation"><a href="#submission" data-toggle="tab" className="border-0" onClick={() => setTab("submission")} title={t('Submission')}> <TextToSpeech text={t('Submission')} /></a></li>
                                                <li role="presentation"><a href="#result" data-toggle="tab" className="border-0" onClick={() => setTab("result")} title={t('Result')}><TextToSpeech text={t('Result')} /></a></li>
                                                <li role="presentation"><a href="#classes" data-toggle="tab" className="border-0" onClick={() => setTab("class")} title={t('Online Class')}> <TextToSpeech text={t('Online Class')} /></a></li>
                                            </ul>
                                            {tab === "class" ?
                                                <div className="profile-tab-rightside mr-1">
                                                    <div className="createnewclass-btn">
                                                        <a href="javaScript:" data-target="#ADDClass_Modal" onClick={clickOnlineClassCreate} title={t('Create New Class')}><TextToSpeech text={t('Create New Class')} /></a>
                                                    </div>
                                                </div> :
                                                tab === "homework" ?
                                                    <div className="profile-tab-rightside">
                                                        <div className="createnewclass-btn">
                                                            <a href="javaScript:" data-target="#ADDClass_Modal" onClick={clickHomeWorkCreate} title={t('Create Homework')}><TextToSpeech text={t('Create Homework')} /></a>
                                                        </div>

                                                    </div>
                                                    : null}
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="homework" role="tablist" data-message={t("Homework list")}>
                                            <div className="row justify-content-start pt-4">
                                                {
                                                    homeworklist.length > 0 ? homeworklist.map((value, i) => (

                                                        <div className="col-md-6" key={i} role="presentation">
                                                            <div className="academic-box">
                                                                <div className="academic-header">
                                                                    <div className="academic-profile">
                                                                        <img alt={t("Academic image")} src="assets/images/academic.png" />
                                                                    </div>
                                                                    <div className="academic-title">
                                                                        <h3><TextToSpeech text={value.homework_title} /></h3>
                                                                    </div>
                                                                </div>
                                                                <div className="academic-body">
                                                                    <label className="posted"><TextToSpeech text={t('Posted by')} />: <b><TextToSpeech text={value.teacher_name} /></b></label>
                                                                    <h3><Link title={value.homework_question} to={"/home-work-details-teacher"} state={{ homworkData: value, myClassDetails: classDetails }}><TextToSpeech text={value.homework_question} /></Link></h3>
                                                                    <label className="submission-date"><TextToSpeech text={t('Last Submission Date')} />: <TextToSpeech text={moment(value.homework_last_submission_date).format("DD MMMM, YYYY")} />  </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="col-md-12">
                                                        <center><p><TextToSpeech text={t('No homework')} /></p></center>
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                        <div className="tab-pane" id="submission">
                                            <div className="row justify-content-start pt-4" role="tablist" data-message={t("Submitted homework list")}>
                                                {
                                                    homeworkSubmitlist.length > 0 ? homeworkSubmitlist.map((value, i) => (
                                                        <div className="col-md-6" key={i} role="presentation">
                                                            <div className="academic-box">
                                                                <div className="academic-header">
                                                                    <div className="academic-profile">
                                                                        <img src={value.student_profile} alt={`${value.student_profile} ${t("image")}`}/>
                                                                    </div>
                                                                    <div className="academic-title w-100">
                                                                        <span className={`float-right  text-capitalize ${value.status === "rejected" ? 'text-danger' : 'text-success'}`}><TextToSpeech text={t(value.status)} /></span>
                                                                        <h3><TextToSpeech text={value.student_name} /></h3>
                                                                        <label className="submission-date"><TextToSpeech text={moment.utc(value.created_at).local().format("DD MMMM, YYYY | h:mm a")} /></label>
                                                                    </div>
                                                                </div>
                                                                <div className={`academic-body ${value?.status === "Pending" ? "" : value.status === "rejected" ? 'academic-resubmision' : 'result-body'}`}>
                                                                    <h3><Link title={value.homework_question} to={"/home-work-submit-details"} state={{ homworkData: value, myClassDetails: classDetails }}><TextToSpeech text={value.homework_question} /></Link></h3>
                                                                    <label className="submission-date"><TextToSpeech text={`${t('Home Work Mode')} : ${(value.homework_type === "text") ? t('Text Box') : (value.homework_type === "file") ? t('File') : t('Text Box And File')}`} />  </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="col-md-12">
                                                        <center><p><TextToSpeech text={t('No homework submit')} /></p></center>
                                                    </div>
                                                }


                                            </div>
                                        </div>

                                        <div className="tab-pane" id="result">
                                            <div className="row justify-content-start pt-4" role="tablist" data-message={t("Homework details")}>
                                                {
                                                    homeworkResultlist.length > 0 ? homeworkResultlist.map((value, i) => (
                                                        <div className="col-md-6" key={i} role="presentation">
                                                            <div className="academic-box">
                                                                <div className="academic-header">
                                                                    <div className="academic-profile">
                                                                        <img alt={value.student_profile} src={value.student_profile} />
                                                                    </div>
                                                                    <div className="academic-title w-100">
                                                                        <span className={`float-right  text-capitalize text-success`}><TextToSpeech text={t(value.mark)} /> <span className={`float-right  text-capitalize text-secondary`}> / {t('100')}</span></span>
                                                                        <h3><TextToSpeech text={value.student_name} /></h3>
                                                                        <label className="submission-date"><TextToSpeech text={moment.utc(value.created_at).local().format("DD MMMM, YYYY | h:mm a")} /></label>
                                                                    </div>
                                                                </div>
                                                                <div className={`academic-body result-body`}>
                                                                    <h3><Link title={value.homework_question} to={"/home-work-submit-details"} state={{ homworkData: value, myClassDetails: classDetails }}><TextToSpeech text={value.homework_question} /></Link></h3>
                                                                    <label className="submission-date"><TextToSpeech text={`${t('Home Work Mode')} : ${(value.homework_type === "text") ? t("Text Box") : (value.homework_type === "file") ? t("File") : t("Text Box And File")}`} />  </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="col-md-12">
                                                        <center><p><TextToSpeech text={t('No homework result')} /></p></center>
                                                    </div>
                                                }


                                            </div>
                                        </div>

                                        <div className="tab-pane" id="classes">
                                            <div className="row mt-3" role="tablist" data-message={("Lecture list")}>

                                                {lecture.length > 0 ? lecture.map((value, index) => (
                                                    <div className="col-lg-6  mb-3" key={index} role="presentation">
                                                        <div className="other-class-box">
                                                            <div className="className-icon">
                                                                <img alt={t("Online class image")} src={`${env.ONLINECLASS_PNG}onlineclass.png`} />
                                                            </div>
                                                            <div className="className">
                                                                <div className="class-info">
                                                                    <div className="key"><TextToSpeech text={`${t('Class')}: ${t(classDetails.class_name) + " - " + t(classDetails.division_name)}`} /> </div>
                                                                    {/* <div className="pair"> {classDetails.class_name + " - " + classDetails.division_name}</div> */}
                                                                </div>
                                                                <div className="class-info">
                                                                    <div className="key"><TextToSpeech text={`${t('Timings')}: ${t(time12Hour(value.lecture_time))}`} /></div>
                                                                    {/* <div className="pair"> {value.lecture_time}</div> */}
                                                                </div>
                                                                <div className="class-info">
                                                                    <div className="key"><TextToSpeech text={`${t('Subject')} : ${t(value.lecture_subject_name)}`} /></div>
                                                                </div>
                                                                <div className="class-info">
                                                                    <div className="key"><TextToSpeech text={`${t('Teacher name')} :  ${t(value.lecture_teacher_name)}`} /></div>
                                                                    {/* <div className="pair"> {value.lecuture_teacher_name}</div> */}
                                                                </div>
                                                                <div className="class-info">
                                                                    <div className="key"><TextToSpeech text={`${t('Duration')}: ${t(timeDurationDetails(value.lecture_duration))}`} /></div>
                                                                </div>
                                                                <div className="class-info">
                                                                    <div className="key"><TextToSpeech text={`${t('Lecture Date')} : ${t(value.lecture_date)}`} /></div>
                                                                </div>
                                                                <div className="class-info my-2">
                                                                    <div className="key add-friend-section link-button">{t('Link')} :
                                                                        <a title={t('Join Now')} target={"_blank"} className="add-friend p-2 mx-2" href={value.lecture_link} ><TextToSpeech text={t('Join Now')} /></a>
                                                                    </div>
                                                                </div>
                                                                <div className="class-info">
                                                                    {/* <div className="key">Instruction:  {value.lecture_instruction}</div> */}
                                                                    <div className="key add-friend-section link-button  my-1">{t('Instruction')}:
                                                                        <a className="add-friend p-2 mx-2" href="javaScript:" data-target="#Instruction_Modal" data-toggle="modal" onClick={() => {
                                                                            setInstructionData(value?.lecture_instruction)
                                                                            setonlineClassData(value)
                                                                        }} title={t('Instruction')}><TextToSpeech text={t('Instruction')} /></a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="more">
                                                                <div className={`more-option bottom-more-option menu-${value?.lecture_id}`}>
                                                                    <div className="delete">
                                                                        <a href='javaScript:' title={t('Delete Lecture')} onClick={() => handleDeleteClass(value.lecture_id)}>  <TextToSpeech text={t('Delete Lecture')} /></a>
                                                                    </div>
                                                                    <div className="edit">
                                                                        <a href="javaScript:" data-target="#ADDClass_Modal" title={t('Edit Lecture')} onClick={() => handleEditClass(value)} ><TextToSpeech text={t('Edit Lecture')} /></a>
                                                                    </div>
                                                                </div>
                                                                <a href='javaScript:' title={t('Combine')} onClick={() => handleMore(value?.lecture_id)}><img src={`${env.COMBINED_SHAPE_PNG}combined-shape-2.png`} /></a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <div className="col-md-12">
                                                        <center><p><TextToSpeech text={t('No online class')} /></p></center>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-side">

                                    <div className="school-right" role="tabpanel">
                                        <h3><TextToSpeech text={t('School')} /></h3>
                                        <div className="school-img">
                                            {schoolData != "" &&
                                                <>
                                                    <img alt={t("School logo")} src={schoolData.school_logo_full_path} className="s-img" />
                                                    <div className="school-name">
                                                        <h4><TextToSpeech text={schoolData.school_name} /></h4>
                                                        <h4><img alt={t("School location icon")} src="assets/web/location-1.png" /><TextToSpeech text={schoolData.school_location} /></h4>

                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Modal instructrionData={instructionData} onlineClassdata={onlineClassdata} isUpdate={(data) => data === true && setInstructionData("")} />
        </React.Fragment >
    )
}

export default My_Homework_Teacher
