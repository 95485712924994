import React, { useEffect } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import myclassbg from "./my-class-img/myclassbg.png"
import myclasslogo from "./my-class-img/ClassLogo.jpg"
import { division_details_api } from '../../../services/user_apis';
import { Link, useNavigate } from 'react-router-dom';
import { home_work_list_body, user_list_api, lecture_list_api, teacher_class_division_list, submission_list_teacher, cheer_me_up, school_detail } from '../../../services/user_apis';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech';
import { time12Hour, timeDurationDetails } from '../../../Utils/TimeUtils';

const My_Homework = ({ socketData }) => {
    const { t } = useTranslation();
    let user = JSON.parse(localStorage.getItem('user'));
    let [class_list, setClassList] = React.useState([])
    let [post, setpost] = React.useState([])
    let [homeworklist, sethomeworklist] = React.useState([])
    let [annoucement, setannoucement] = React.useState([])
    let [myfriends, setmyfriends] = React.useState([])
    let [lecture, setlecturelist] = React.useState([])
    let [resultList, setResultList] = React.useState([])
    let navigate = useNavigate();
    let [classDetails, setClassDetails] = React.useState({});
    let [cheermeup, setCheermeup] = React.useState([]);
    let [instructionData, setInstructionData] = React.useState("");
    let [divisionDetails, setDivisionDetails] = React.useState(null)
    let [schoolData, setschooldata] = React.useState([]);
    let [onlineClassdata, setonlineClassData] = React.useState(null);


    useEffect(() => {
        const user_list = async () => {
            try {
                const response = await user_list_api(user.class_id)
                if (response.code === 1) {
                    setmyfriends(response.data)
                    // setcircular(false)
                }
            }
            catch (e) {
                navigate('/')
            }
        }
        user_list()
    }, [])

    useEffect(() => {
        const class_list = async () => {
            if (user.user_role === "teacher") {
                const response = await teacher_class_division_list()
                if (response.code === 1) {
                    setClassList(response.data)
                }
            }
        }
        class_list()
        school_details()
    }, [])

    const school_details = async () => {

        const school_data = await school_detail(user.school_id)
        if (school_data.code === 1) {
            setschooldata(school_data.data);
        } else {
            setschooldata("")
        }

    }
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        setClassDetails({ "class_name": user.class_name, "school_name": user.school_name, "division_name": user.division_name })
        getDivisionDetails(user.division_id)
        let current_time = moment().format();
        const post_body = {
            current_datetime: current_time,
            class_id: user.class_id,
            division_id: user.division_id,
        }
        const lecuture_list = async () => {
            const response1 = await lecture_list_api(post_body)
            if (response1.code === 1) {
                setlecturelist(response1.data)
            }
        }
        lecuture_list()

    }, [])

    const handleHomeworkList = () => {
        let post_body = { "class_id": user.class_id, "student_id": user.user_id, "division_id": user.division_id }
        const homework_list_api = async () => {
            const response = await home_work_list_body(post_body)
            if (response.code === 1) {
                sethomeworklist(response.data)
            }
        }
        homework_list_api()
    }

    const handleResultList = () => {
        var body = { "class_id": user.class_id, "student_id": user.user_id, "division_id": user.division_id }
        const submission_list_student_api = async () => {
            const response = await submission_list_teacher(body)
            if (response.code === 1) {
                setResultList(response.data)
            }
        }
        submission_list_student_api()
    }

    const getDivisionDetails = async (division_id) => {
        let post_body = {
            division_id: division_id
        }
        try {
            const response = await division_details_api(post_body)
            if (response.code === 1) {
                setDivisionDetails(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }

    }


    const getPassDataOtherUser = (data) => {
        var dataPass = { "user_id": data.user_id, "username": data.username, "display_user_name": data.display_user_name, "user_about_me": data.user_about_me, "avatar_full_path": data.avatar_full_path }
        return dataPass
    }

    return (
        <React.Fragment>

            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid text-left">
                        <div className="row">
                            <div className="col-lg-8 pr-md-0" data-message={("Homework ")}>
                                <div className="main-content">
                                    <div className='my-profile school-profile myclass-profile'>
                                        <div className="p-banner" style={{ backgroundimage: `url(${(divisionDetails && divisionDetails.division_cover_image_full_path) ? divisionDetails.division_cover_image_full_path : myclassbg})` }}>
                                            <img alt={t("Division cover image")} src={(divisionDetails && divisionDetails.division_cover_image_full_path) ? divisionDetails.division_cover_image_full_path : myclassbg} />
                                            {
                                                user.user_role === "teacher" && <div className="my-class-dropdown">
                                                    <select aria-label={t("Select class")} className="form-control">
                                                        {class_list.map((value, i) => (
                                                            <option key={i}><TextToSpeech text={value.class_name} /></option>
                                                        ))}

                                                    </select>
                                                </div>
                                            }

                                        </div>
                                        <div className="p-icon">
                                            <div className="p-main-pic">
                                                <img alt={t("Division logo image")} src={(divisionDetails && divisionDetails.division_logo_image_full_path) ? divisionDetails.division_logo_image_full_path : `${env.FRIST_CLASS_SVG}first-class.svg`} />
                                            </div>
                                        </div>
                                        <div className="p-name pb-3">
                                        <TextToSpeech text={classDetails.class_name + " - " + classDetails.division_name} />
                                        </div>
                                        <div className="post-content">
                                            <ul role="tablist" className="nav nav-tabs">
                                                <li role="presentation"><a href="#classes" data-toggle="tab" className="border-0 active" title={t('Online Class')}> <TextToSpeech text={t('Online Class')} /></a></li>
                                                <li role="presentation"><a href="#homework" data-toggle="tab" className="border-0 " onClick={handleHomeworkList} title={t('Homework')}> <TextToSpeech text={t('Homework')} /></a></li>
                                                <li role="presentation"><a href="#result" data-toggle="tab" className="border-0" onClick={handleResultList} title={t('Result')}> <TextToSpeech text={t('Result')} /></a></li>
                                            </ul>
                                            {
                                                user.user_role === "teacher" && <div className="profile-tab-rightside">
                                                    <div className="createnewclass-btn">
                                                        <a href="#" title={t('Create New lecture')} data-target="#ADDClass_Modal" data-toggle="modal"><TextToSpeech text={t('Create New lecture')} /></a>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="classes">
                                            <div className="row mt-3">

                                                {
                                                    lecture.length > 0 ? lecture.map((value, i) => (
                                                        <div className="col-lg-6  mb-3" key={i}>
                                                            <div className="other-class-box">
                                                                <div className="className-icon">
                                                                    <img src={`${env.ONLINECLASS_PNG}onlineclass.png`} />
                                                                </div>
                                                                <div className="className">
                                                                    <div className="class-info">
                                                                        <div className="key"><TextToSpeech text={`${t('Class')} : ${classDetails.class_name + " - " + classDetails.division_name}`} /></div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key"><TextToSpeech text={`${t('Timings')} : ${time12Hour(value.lecture_time)}`} /></div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key"><TextToSpeech text={`${t('Subject')} : ${value.lecture_subject_name}`} /></div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key"><TextToSpeech text={`${t('Teacher name')} :  ${value.lecture_teacher_name}`} /></div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key"><TextToSpeech text={`${t('Duration')} : ${timeDurationDetails(value.lecture_duration)}`} /></div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key"><TextToSpeech text={`${t('Lecture Date')} : ${t(value.lecture_date)}`} /></div>
                                                                    </div>
                                                                    <div className="class-info my-2">
                                                                        <div className="key add-friend-section link-button">{t('Link')} :
                                                                            <a title={t('Join Now')} target={"_blank"} className="add-friend p-2 mx-2" href={value.lecture_link} ><TextToSpeech text={t('Join Now')} /></a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="class-info ">
                                                                        <div className="key add-friend-section link-button  my-1">{t('Instruction')} :
                                                                            <a title={t('Instruction')} className="add-friend p-2 mx-2" href="javaScript:" data-target="#Instruction_Modal" data-toggle="modal" onClick={() =>{ setInstructionData(value?.lecture_instruction)
                                                                            setonlineClassData(value)}} ><TextToSpeech text={t('Instruction')} /></a>
                                                                        </div>
                                                                        {/* <div className="key">Instruction:  {value.lecture_instruction}</div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="col-md-12">
                                                        <center><p><TextToSpeech text={t('No online class')} /></p></center>
                                                    </div>
                                                }




                                            </div>
                                        </div>

                                        <div className="tab-pane " id="homework">
                                            <div className="row pt-4" data-message={t('Homework list')}>
                                                {
                                                    homeworklist.length > 0 ? homeworklist.map((value, i) => (
                                                        <div className="col-md-6" key={i}>
                                                            <div className="academic-box">
                                                                <div className="academic-header">
                                                                    <div className="academic-profile">
                                                                        <img alt={t('Instruction')} src="assets/images/academic.png" />
                                                                    </div>
                                                                    <div className="academic-title">
                                                                        <span className={`float-right  text-capitalize ${value?.submission_status === "Pending" ? "text-warning" : value?.submission_status === "rejected" ? 'text-danger' : 'text-success'}`}><TextToSpeech text={t(value?.submission_status)} /></span>
                                                                        <h3><TextToSpeech text={value?.homework_title} /></h3>
                                                                        <span><TextToSpeech text={moment(value?.created_at).format("DD MMMM, YYYY | h:mm a")} /></span>
                                                                    </div>
                                                                </div>
                                                                <div className={`academic-body ${value?.submission_status === "Pending" ? "" : value?.submission_status === "rejected" ? 'academic-resubmision' : 'result-body'}`}>
                                                                    <label className="posted"><TextToSpeech text={t('Posted by')} />: <b><TextToSpeech text={value?.teacher_name} /></b></label>
                                                                    <h3><Link title={value?.homework_question} to={"/homeworkDetails"} state={{ homworkData: value }}><TextToSpeech text={value?.homework_question} /></Link></h3>
                                                                    <label className="submission-date"><TextToSpeech text={t('Last Submission Date')} />: <TextToSpeech text={value?.homework_last_submission_date} /></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="col-md-12">
                                                        <center><p><TextToSpeech text={t('No homework')} /></p></center>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="tab-pane" id="result">
                                            <div className="row pt-4" data-message={t('Homework results')} role="tablist">
                                                {
                                                    resultList.length > 0 ? resultList.map((value, i) => (
                                                        <div className="col-md-6" key={i} role="presentation">
                                                            <div className="academic-box">
                                                                <div className="academic-header">
                                                                    <div className="academic-profile">
                                                                        <img alt={t('Academic')} src="assets/images/academic.png" />
                                                                    </div>
                                                                    <div className="academic-title">
                                                                        <span className={`float-right  text-capitalize text-success`}><TextToSpeech text={t(value.mark)} /> <span className={`float-right  text-capitalize text-secondary`}> / {t('100')}</span></span>
                                                                        <span className="float-right text-warning "><TextToSpeech text={t(value?.submission_status)} /></span>
                                                                        <h3><TextToSpeech text={value?.homework_title} /></h3>
                                                                        <span><TextToSpeech text={moment(value?.created_at).format("DD MMMM, YYYY | h:mm a")} /></span>
                                                                    </div>
                                                                </div>
                                                                <div className={`academic-body ${value?.submission_status === "Pending" ? "" : value?.submission_status === "result" ? 'academic-resubmision' : 'result-body'}`}>
                                                                    <label className="posted"><TextToSpeech text={t('Posted by')} />: <b><TextToSpeech text={classDetails.class_name + " - " + classDetails.division_name} /></b></label>
                                                                    <h3><Link to={"/result-details-student"} state={{ homworkData: value, myClassDetails: classDetails }}><TextToSpeech text={value?.homework_question} /></Link></h3>
                                                                    <label className="submission-date"><TextToSpeech text={t('Last Submission Date')} />: <TextToSpeech text={moment(value?.created_at).format("DD MMMM, YYYY")} /> </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="col-md-12">
                                                        <center><p><center><TextToSpeech text={t('No result')} /></center></p></center>
                                                    </div>
                                                }


                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-side">
                                    <div className="school-right" data-message={("School details")}>
                                        <h3>{t('School')}</h3>
                                        <div className="school-img">
                                            {schoolData != "" &&
                                                <>
                                                    <img alt={t('School logo')} src={schoolData.school_logo_full_path || myclasslogo} className="s-img" />
                                                    <div className="school-name">
                                                        <h4><TextToSpeech text={schoolData.school_name} /></h4>
                                                        <h4><img alt={t('Location')} src="assets/web/location-1.png" /><TextToSpeech text={schoolData.school_location} /></h4>

                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="member-right">
                                        <h3><TextToSpeech text={`${t('Members in this Class')} ${t(myfriends.length)}`} /></h3>
                                        <div className="mem-list">
                                            <ul role="tablist">
                                                {myfriends && myfriends.map((value, i) => (
                                                    <li key={i} role="presentation">
                                                        <Link title={t("Friend link")} to="/otherUserProfile" state={{ "otherUserData": getPassDataOtherUser(value) }}>
                                                            <img alt={t('Avatar')}  src={value?.avatar_full_path ? `${value?.avatar_full_path}` : `${env.PRO_IMG}pro-img.png`} />
                                                            <TextToSpeech text={value?.display_user_name ? value?.display_user_name : value?.username} />
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Modal instructrionData={instructionData} onlineClassdata={onlineClassdata} isUpdate={(data) => data === true && setInstructionData("")} />


        </React.Fragment >
    )
}

export default My_Homework
